$colorDeepPurple: hsl(265, 97%, 15%); //#20014b
$colorFlashTurk: hsl(181, 58%, 53%); //#42cacd
$colorCrackedYolk: hsl(34, 99%, 62%); //#feab3e
$colorSalmonRouge: hsl(351, 98%, 78%); //#fe90a0
$colorMurkyNight: hsl(208, 42%, 6%); //#091016
$colorFadedMing: hsl(208, 42%, 69%); //#8db1d1
$colorThunderCloud: hsl(208, 42%, 80%); //#b7cde1
$colorPearlyCoke: hsl(210, 37%, 97%); //#f4f7fa

$fontSans: "Matter", sans-serif;
$fontMono: "GT America Mono", monospace;
$fontSerif: "FF Franziska", serif;

$scaleFactor: 1.24 !default;

$colorShade: $colorMurkyNight;
$headingLineHeight: 1.6;

$scaleFactor: 1.16;
$inputBorder-FocusColor: $colorFlashTurk !default;

$tableBorderColor: $colorSlate-10;

$textHighlightBg: $colorFlashTurk;
$textShade: $colorCrimson-80;

$sidebarTextColorOnHover: $colorCrimson-90;

// Floating labels when collapsed
$sidebBarCollapsedLabelBG: $colorCrimson-90;
