.docs-header {
    position : fixed;
    z-index  : 5000;
    width    : 100%;
    left     : 0;
    right    : 0;
}

.docs-header .portion {
    padding-top    : 8px !important;
    padding-bottom : 8px !important;
}

.menu-toggle {
    display : none;
    cursor  : pointer;
}

.main-header-logo,
.main-header-logo img { height: 40px; }

// .App-logo {
//     display : flex;
//     height  : 24px;
//     width   : auto;
// }

@media (max-width: 900px) {
    .menu-toggle * { line-height: 6px; }

    .main-header-logo { margin: 0 auto; }

    .menu-toggle {
        display     : block;
        margin-left : 4vw;
        position    : fixed;
    }
}