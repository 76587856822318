///////////////////////////////////////////////////////////////////////////////
//  BASICS
///////////////////////////////////////////////////////////////////////////////
.content-wrapper {
    min-height: 100vh;
}

@media (max-width : 600px) {
    .padded-content {
        padding-left: 4vw;
        padding-right: 4vw;
    }
}

button,
.ff-button {
    font-size: 92%;
}

button.shape-rounded,
.ff-button.shape-rounded {
    border-radius: 4px;
}

//TODO: Move this to the FFS repo
body {
    font-feature-settings: "ss03";
}


// TODO Move this to FFS
code,
:not(pre)>code {
    margin: 0 3px;
    padding: 2px 6px;
    font-size: 86%;
}

code,
:not(pre)>code {
    background-color: rgba($colorFlashTurk, 0.12);
}

pre code,
pre[class*=language-],
code[class*=language-] {
    width: 100%;
    border-radius: 4px;
    font-family: $fontMono;
    font-size: 82%;
    padding: 24px;
    background-color: rgba($colorSlate-10, 0.4);
    color: rgba($colorDeepPurple, 0.64);
    white-space: pre-wrap;
    word-break: break-all;
}

ol.numbered-list li,
ul.numbered-list li {
    width: 100%;
    list-style: unset;
    margin-bottom: 24px;
}

ul.list-inside-list li {
    width: 100%;
    margin-left: 24px;
    margin-top: 10px;
    list-style-type: circle;
    list-style-position: inside;
    margin-bottom: 24px;
}


.content-wrapper article {
    padding-top: 32px;
}

.content-wrapper li:last-of-type {
    margin-bottom: 0
}

@media (max-width : 600px) {

    .ff-table.padding-small th,
    .ff-table.padding-small td {
        padding: 8px;
    }

    pre code,
    pre[class*=language-],
    code[class*=language-] {
        max-width: calc(100vw - 8vw - 16px);
        overflow-x: scroll;
    }
}


code,
:not(pre)>code {
    margin: 0 0 !important;
}

.header-logo {
    padding: 56px;
}

.sidebar-wrapper.visible {
    left: 0;
}

.sidebar-wrapper a.active {
    background-color: rgba($colorCrimson-90, 0.16);
    border-left: 4px solid $colorCrimson-90;
}

.sidebar-text {
    font-weight: 400;
}

.bottom-section .setu-logo img {
    width: 64px;
}

.sidebar-wrapper hr {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.section-header {
    font-weight: 600;
}

.bottom-section .sidebar-icon {
    width: 0 !important;
    height: 0 !important;
}

.sidebar-wrapper .sidebar-icon svg {
    fill: none;
    stroke: $colorSlate-80;
    // stroke       : $colorCrimson-90;
    stroke-width: 2;
}

// a[aria-current="page"] .sidebar-wrapper .sidebar-icon svg {}

.sidebar-wrapper.collapsed .bottom-section .sidebar-icon {
    width: 32px !important;
    height: 32px !important;
}

.sidebar-wrapper.collapsed .setu-logo {
    display: none !important;
}

.sidebar-wrapper.collapsed .sidebar-item:hover .sidebar-icon+.sidebar-text {
    background-color: $colorCrimson-90;
}

.sidebar-sublink {
    font-size: 0.92em;
}

///////////////////////////////////////////////////////////////////////////////
//  TRANSITION
///////////////////////////////////////////////////////////////////////////////
.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.gCBUqg>ul>li.react-tabs__tab--selected {
    background: #ae275d0a;
}

.rendered-markdown {

    ol, ul{
        padding: 1vmin;
    }
    ol li,
    ul li {
        margin-left: 5vmin;
    }

    ol li {
        list-style-type: decimal;
        margin-bottom: 2vmin;
    }

    ul li {
        list-style-type: circle;
    }
    h1, h2, h3, h4, h5{
        margin-bottom: 2vmin;
    }
    
    td,
    th {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 1vmin;
    }
    tr:nth-child(odd) {
        background-color: #fefefe;
    }
    table {
        margin-bottom: 4vmin;
    }
        
    pre code,
    pre[class*=language-],
    code[class*=language-] {
        width: 100%;
        border-radius: 4px;
        font-family: $fontMono;
        font-size: 82%;
        padding: 24px;
        background-color: #FBECF3;
        color: rgba($colorDeepPurple, 0.64);
        white-space: pre-wrap;
        word-break: break-all;
    }

    pre {
        margin-bottom: 15px;
    }

}
@media (max-width : 600px) {
    article {
        margin-top: 6vh;
    }
}


a {
    color: $colorCrimson-90;
}
