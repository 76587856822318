#page-api-docs .menu-toggle { display: none; }

.redoc-wrap {
    width       : 100%;
    position    : relative;
}


.api-content h2 { margin-top: 16px; }

.api-content h3 {
    font-size   : 16px;
    margin      : 24px 0 8px 0;
    font-weight : 400;
}

#page-api-docs tr > td:nth-child(2) { border-bottom: 1px solid rgba(216, 215, 219, 0.4); }


.redoc-json .token, .redoc-json .code {
    color: black!important;
    background-color: #FBECF3!important;
}

.api-content .react-tabs__tab-list {
    display: none;
}

.redoc-json .token{
    font-size: medium;
}

.redoc-json :not(pre)>code, code {
    background-color: #FBECF3!important;
    font-size: medium;
}

.sc-esjQYD.fidtzk{
    padding: 10px;
}